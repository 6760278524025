import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

const Support = () => {

    return (
        <div className="support">
        <div className="instructions-container">
            <Link to="/"><div className="blue-left-arrow"></div></Link>
        </div>
        <div className="support-card">
        In case of discrepancies, issues or suggestions please email reports@takahami.com

        </div>
    </div>
    );
};

export default Support;


