import React from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { signOut } from "aws-amplify/auth";
import { useAuthenticator } from "@aws-amplify/ui-react";

const Headers = () => {
    const { user } = useAuthenticator((context) => [context.user]);
    const logout = async() => {
        await signOut()
        .then(() => {
          console.log('User signed out successfully');
        })
    }
    return (
        <>
        {user && (
           <div className="pointer logout-btn" onClick={logout}>Logout <FaSignOutAlt /></div>
           )}
        </>
    );
};

export default Headers;
