
import { useEffect, useState } from "react";

import { Amplify } from "aws-amplify";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { Navigate } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import logo from '../../Images/takahami logo.png';
import './index.css';

Amplify.configure({
  Auth: {
    Cognito: {
      identityPoolId: "us-east-1:15920c45-b49b-4813-9f79-fdf67a7eb1b5",
      userPoolClientId: "6pb3cpb9poqbgp3ed2gk2h5u7d",
      userPoolId: "us-east-1_VhZ5kV4NM",
      signUpVerificationMethod: "code",
      loginWith: {
        email: true,
      },
      region: "us-east-1",
    },
  },
});

function Login() {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (authStatus === "authenticated") {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [authStatus]);
  return (
    <div className="login-component">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={logo} className="image"></img>
      </div>
      <div className="login-component-middle">
        <div className="login-component-middle-inner">
          <Authenticator loginMechanisms={["email"]} hideSignUp >
            {() => <Navigate to="/" />}
          </Authenticator>
        </div>
      </div>
    </div>
  );
}

export default Login;
