import axios from "axios";
const apiConfig = "https://bhkwl9p1bj.execute-api.us-east-1.amazonaws.com/dev/takahami"

let locList = [];
let facilityList = [];
let insuranceList = [];

export const getDetails = async (prefix, facility,loc) => {
    try {
        let path = "/cr/data";
       path += prefix ? `?prefix=${prefix}` : "";
       path += facility ? `&facility=${facility}` : "" ;
       path +=loc ? `&loc=${loc}`: "" ;

       console.log({path});

        const response = await axios.get(apiConfig + path);
        return response
    } catch (error) {
        return "collection details is not defined";
    }
};

export const getInsuranceDetails = async (prefix, year,insurance) => {
    try {
        let path = "/pl/data";
       path += prefix ? `?prefix=${prefix}` : "";
       path += year ? `&year=${year}` : "" ;
       path +=insurance ? `&insurance=${insurance}`: "" ;

       console.log({path});

        const response = await axios.get(apiConfig + path);
        return response
    } catch (error) {
        return "Insurance Details is not defined" + error;
    }
};

export const getLegacyHealing = async (prefix,insurance) => {
    try {
        let path = "/lh/data";
       path += prefix ? `?prefix=${prefix}` : "";
       path +=insurance ? `&insurance=${insurance}`: "" ;

       console.log({path});

        const response = await axios.get(apiConfig + path);
        return response
    } catch (error) {
        return "Legacy Healing Details is not defined" + error;
    }
};

export const getLocList = async (id, value) => {
    try {
        let path = `/loc/data`;

        const response = await axios.get(apiConfig + path);
        locList = response?.data?.locList; 
        console.log({locList});
        return response
    } catch (error) {
        return "locList is not defined" + error;
    }
};

export const getFacilityList = async (id, value) => {
    try {
        let path = `/facility/data`;

        const response = await axios.get(apiConfig + path);
        facilityList = response?.data?.facilityList; 
        console.log({facilityList});
        return response
    } catch (error) {
        return "facilityList is not defined" + error;
    }
};

export const getInsuranceList = async (prefix) => {
    try {
        let path = `/prefixinsurance/data`;
        path += prefix ? `?prefix=${prefix}` : "";

        const response = await axios.get(apiConfig + path);
        insuranceList = response?.data; 
        console.log({insuranceList});
        return response
    } catch (error) {
        return "insuranceList is not defined" + error;
    }
};

export { locList,facilityList,insuranceList };

