import React, { useState, useEffect } from "react";
import Dropdown from '../../component/dropdown';
import { Link } from 'react-router-dom';
import logo from '../../Images/takahami logo.png'
import CustomSelect from '../../component/dropdown'
import HomeIcon from '@mui/icons-material/Home';
import { insuranceList, getInsuranceList, getLegacyHealing } from '../../helper/api'

const Prefix2 = () => {

    const [insurance, setInsurance] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [response, setResponse] = useState('');
    let insuranceValues = insuranceList

    const handleInsurance = async (data) => {
        setInsurance(data);
        const response = await getLegacyHealing(inputValue, data);
        setResponse(response?.data)
        console.log({ response });
        if (response?.data?.length > 0) {
            const formattedData = response?.data.map(item => {
                const formattedItem = {};
                for (const [key, value] of Object.entries(item)) {
                    formattedItem[key] = parseFloat((value * 100).toFixed(1));
                }
                return formattedItem;
            });
            const mergedData = Object.assign({}, ...formattedData);
            setResponse(mergedData)
        }
    };

    const handlePrefix = async (event) => {
        setInputValue(event.target.value);
        const response = await getLegacyHealing(event.target.value, insurance);
        await getInsuranceList(event.target.value);
        setResponse(response?.data)
        console.log({ response });

        const formattedData = response?.data.map(item => {
            const formattedItem = {};
            for (const [key, value] of Object.entries(item)) {
                formattedItem[key] = parseFloat((value * 100).toFixed(1));
            }
            return formattedItem;
        });
        const mergedData = Object.assign({}, ...formattedData);
        setResponse(mergedData)
    };
    console.log("grfddhfhffjgjh--->", response);


    useEffect(() => {
        const fetchData = async () => {
            // await getInsuranceList();
            await handleInsurance();
            setInsurance(insuranceValues[0]?.value);
        };

        fetchData();
    }, []);

    return (
        <div className="collections">
            <div className="prefix-container">
                <Link to="/prefix"><div className="blue-left-arrow"></div></Link>
                <Link to="/"><HomeIcon sx={{ fontSize: 70, color: 'rgb(56,96,178)', marginLeft: "40px" }} /></Link>
            </div>
            <div className="prefix-header">
                <div className="form">
                    <div className="form-font">California</div>
                    <table className="padding width">
                        <tbody>
                            <tr>
                                <td className="flex-container">
                                    <label>Prefix Search</label>
                                    <input className="prefix-text"
                                        id="prefixLookup"
                                        type="text"
                                        value={inputValue}
                                        onChange={handlePrefix}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="flex-container">
                                    <div className="font-label prefix-label">Insurance</div>
                                    <CustomSelect value={insurance} onChange={handleInsurance} items={insuranceValues} sx={{ marginLeft: '30px', width: '29rem', marginTop: '10px' }} height={45} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="flex">
                    <table className="padding width">
                        <tbody>
                            <tr>
                                <td className="flex-container margin-left padding-prefix">
                                    <div className="placeholder-label font-label">DET</div>
                                    <div className="placeholder-label font-label">RES</div>
                                    <div className="placeholder-label font-label">IOP</div>
                                    <div className="placeholder-label font-label">PHP</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="textarea-prefix font">
                        <table className="padding width">
                            <tbody>
                                <tr>
                                    <td className="flex-container">
                                        {response?.DETPercentage ? (<div className="placeholder-label font-label margin-prefix2">{response.DETPercentage + "%"}</div>) : (<div className=" scale"></div>)}
                                        {response?.RESPercentage ? (<div className="placeholder-label font-label">{response.RESPercentage + "%"}</div>) : (<div className=" scale"></div>)}
                                        {response?.IOPPercentage ? (<div className="placeholder-label font-label">{response.IOPPercentage + "%"}</div>) : (<div className=" scale"></div>)}
                                        {response?.PHPPercentage ? (<div className="placeholder-label font-label">{response.PHPPercentage + "%"}</div>) : (<div className=" scale"></div>)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <img src={logo} className="image-sm"></img>
        </div>
    );
};

export default Prefix2;
