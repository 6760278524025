import React, { useState, useEffect } from "react";
import "./home.css";
import logo from '../../Images/takahami logo.png'
import { Link } from 'react-router-dom';
import {getDetails,getLocList,getFacilityList} from '../../helper/api'
import { MdSupportAgent } from "react-icons/md";
import { FaSignOutAlt } from "react-icons/fa";
import { signOut } from "aws-amplify/auth";

const Home = () => {
    useEffect(() => {
        const fetchData = async () => {
            await getLocList();
            await getFacilityList();
        };
      
        fetchData();
      }, []);
    return (
       <div className="container">
           <div className="content">
            <img src={logo} className="image"></img>
                <div className="buttons">
               <button className="blue-button"> <Link to="/instructions">Instructions</Link></button>
                <button className="blue-button"><Link to="/collections-review">Collections review</Link></button>
                <button className="blue-button"><Link to="/prefix">Prefix lookup</Link></button>
                </div>
           </div>
           <Link to="/support"><MdSupportAgent className="support-icon" /></Link>
       </div>
    );
};

export default Home;


