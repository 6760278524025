import React from "react";
import "./instructions.css";
import { Link } from 'react-router-dom';

const Instructions = () => {
    return (
        <>
            <div className="instructions">
                <div className="instructions-container">
                    <Link to="/"><div className="left-arrow"></div></Link>
                </div>
                <div className="instruction-card">
                    <p>
                        Alpha Prefix Lookup</p>

                    <p>Description: The Alpha Prefix Lookup app is a powerful tool within your company that helps you find the average reimbursement rate for a given health insurance prefix and year.
                        Instructions:</p>
                    <ol>
                        <li> Launching the App:   - Open the "Alpha Prefix Lookup" app on your company device.</li>
                        <li> Searching for Reimbursement Rates: </li>
                        <li> Viewing Results: - The app will display the average reimbursement rate for the given prefix, level of care and year.</li>

                        <p>- To search for reimbursement rates, open Prefix Look up and follow these steps:   </p>

                        <p>a. Enter Prefix: - Input the health insurance three letter prefix in the provided field. </p>
                        <p>b. Select Year: - Use the year or drop-down menu to choose the year you're interested in. </p>

                        <p>- If your looking for California rates, move to page two, you only need to add the three letters prefix.</p>

                        <li> Additional Features (Collections Review): You will be able to determine if a service is being underpaid. Add the prefix, select facility, input charged and paid amounts, select LOC and see the result.</li>
                        <li> Help and Support: - For assistance or questions, refer to the app's "Help" or "Support" section.</li>
                        <li> Updates:   - Keep the app updated to access the latest reimbursement rate data and features.</li>
                        <li> Enjoy Using Alpha Prefix Lookup:   - Utilize the app to quickly find reimbursement rates for healthcare services within your company.</li>
                    </ol>

                </div>
            </div>
        </>
    );
};

export default Instructions;
