import React, { useState, useEffect } from "react";
import "./collections.css";
import { Link } from 'react-router-dom';
import logo from '../../Images/takahami logo.png'
import CustomSelect from '../../component/dropdown'
import {getDetails,getLocList,locList,facilityList,getFacilityList} from '../../helper/api'

const Collections = () => {

    const [facility, setFacility] = useState('');
    const [loc, setLoc] = useState([{value:"",label:""}]);
    const [values, setValues] = useState([]);
    let facilityValues = facilityList.length > 0 ? facilityList : [{value:"",label:""}]
    let locValues = locList.length > 0 ? locList : [{value:"",label:""}]

    console.log({locValues});

      const [inputValue, setInputValue] = useState('');
      const [chargeValue, setChargeValue] = useState('');
      const [paidValue, setPaidValue] = useState('');
      const [response, setResponse] = useState('');

      const totalValue = paidValue && chargeValue ? ((paidValue / chargeValue) * 100).toFixed(1) : null; 
      console.log({totalValue});
      const status = values <= totalValue ? "Paid" : "Underpaid"

      const handlePrefix = async (event) => {
        setInputValue(event.target.value);
        const response = await getDetails(event.target.value, facility, loc);
        const percentage = (response?.data?.length > 0) ? (response?.data[0]["Year Percentage"] * 100).toFixed(1) : 0;
        console.log({percentage});
        percentage ? setValues(percentage) : setValues(0)
        // console.log("data-->",response?.data[0]);
        console.log({response});
        setResponse((response?.data?.length > 0) ? response?.data[0]  : "");
      };

      console.log(values);

    const handleFacility = async (data) => {
        setFacility(data);
        const response = await getDetails(inputValue, data, loc);
        const percentage = (response?.data?.length > 0) ? (response?.data[0]["Year Percentage"] * 100).toFixed(1) : 0;
        console.log({percentage});
        percentage ? setValues(percentage) : setValues([])
        setResponse((response?.data?.length > 0) ? response?.data[0]  : "");
      };

      const handleLoc = async (data) => {
        setLoc(data);
        const response = await getDetails(inputValue, facility , data);
        const percentage = (response?.data?.length > 0) ? (response?.data[0]["Year Percentage"] * 100).toFixed(1) : 0;
        console.log({percentage});
        percentage ? setValues(percentage) : setValues([])
        setResponse((response?.data?.length > 0) ? response?.data[0]  : "");
      };

      useEffect(() => {
        const fetchData = async () => {
          
          setFacility(facilityValues[0].value);
          setLoc(locValues[0]?.value);
          await getLocList();
          await getFacilityList();
          await handleFacility()
        };
      
        fetchData();
      }, []);

    return (
        <div className="collections">
            <div className="collections-container">
                <Link to="/"><div className="blue-left-arrow"></div></Link>
            </div>
            <div className="collections-header">
                <div className="form">
                    <table className="padding width">
                        <tbody>
                            <tr>
                                <td className="flex-container">
                                    <label>Prefix look up</label>
                                    <input
                                    id="prefixLookup"
                                    type="text"
                                    value={inputValue}
                                    onChange={handlePrefix}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="flex-container">
                                    <label>Facility</label>
                                    <CustomSelect value={facility} onChange={handleFacility} items={facilityValues}  sx={{ marginLeft: '70px', width: '19rem',marginTop:'10px'}} height={45}/>
                                </td>
                            </tr>
                            <tr>
                                <td className="flex-container">
                                    <label>Charge amount</label>
                                    <input
                                    id="Chargeamount"
                                    type="text"
                                    value={chargeValue}
                                    onChange={(event) => setChargeValue(event.target.value)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="flex-container">
                                    <label>Paid amount</label>
                                    <input
                                    id="Paidamount"
                                    type="text"
                                    value={paidValue}
                                    onChange={(event) => setPaidValue(event.target.value)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="flex-container">
                                    <label>LOC</label>
                                    <CustomSelect value={loc} onChange={handleLoc} items={locValues}  sx={{ marginLeft: '70px', width: '19rem',marginTop:'10px'}} height={45}/>
                                    <div className="percentage-values">{values>0 ? values + "%" : ""}</div>
                                </td>
                            </tr>
                            <tr>
                                <td className="flex-container">
                                    <div className="placeholder-label font-label">Insurance</div>
                                    <div className="placeholder ">{response?.Insurance}</div>
                                    {/* <CustomSelect value={insurance} onChange={handleInsurance} items={insuranceValues}  sx={{ marginLeft: '30px', width: '35rem',marginTop:'10px'}} height={45}/> */}
                                </td>
                            </tr>
                        </tbody>
                    </table>




                </div>

                <div className="flex">
                    <div className="font-label">Result</div>
                    <div className="textarea font">{ totalValue ===null ? "Complete all the fields in order to provide you with a result" : values >0 ? totalValue + "% - " + status : "No result"}</div>
                </div>
            </div>
            <img src={logo} className="image-small"></img>
        </div>
    );
};

export default Collections;
