import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function CustomSelect({ value, onChange, items, sx,height }) {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const defaultValue = items.length > 0 ? items[0].value : '';

  return (
    <FormControl sx={{ ...sx }}>
      <Select
        value={value || defaultValue}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        variant="outlined"
        sx={{
          height: height,
          backgroundColor: 'rgb(255, 255, 255)',
          border: '2px solid rgb(0, 18, 107)',
          borderRadius: '5px',
          '&:hover': {
            backgroundColor: 'rgb(186, 202, 266)',
          },
          '&:focus': {
            border: 'none',
          },
          '&.Mui-focused': {
            border: 'none',
          },
        }}
      >
        {items.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
