import React, { useState, useEffect } from "react";
import "./prefix.css";
import CustomSelect from '../../component/dropdown'
import { Link } from 'react-router-dom';
import logo from '../../Images/takahami logo.png'
import { insuranceList, getInsuranceList, getInsuranceDetails } from '../../helper/api'

const Prefix = () => {
    const [year, setYear] = useState('');
    const [insurance, setInsurance] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [response, setResponse] = useState('');
    let insuranceValues = insuranceList.length > 0 ? insuranceList : [{ label: "", value: "" }];

    const handleInsurance = async (data) => {
        setInsurance(data);
        const response = await getInsuranceDetails(inputValue, year, data);
        setResponse(response?.data)
        const transformedData = {}
        response?.data?.forEach(currentValue => {
            for (const [category, values] of Object.entries(currentValue)) {
                transformedData[category] = {};
                for (const [key, value] of Object.entries(values)) {
                    transformedData[category][key] = typeof value === 'number' ? (value * 100).toFixed(1) : value;
                }
            }
        });

        setResponse(transformedData);
        console.log({ transformedData });
    };

    const items = [
        { label: 'ALL', value: 'All' },
        { label: '2024', value: '2024' },
        { label: '2023', value: '2023' },
        { label: '2022', value: '2022' }
    ];

    const handleDateChange = async (data) => {
        setYear(data);
        const response = await getInsuranceDetails(inputValue, data, insurance);
        setResponse(response?.data)
        const transformedData = {}
        response?.data?.forEach(currentValue => {
            for (const [category, values] of Object.entries(currentValue)) {
                transformedData[category] = {};
                for (const [key, value] of Object.entries(values)) {
                    transformedData[category][key] = typeof value === 'number' ? (value * 100).toFixed(1) : value;
                }
            }
        });

        setResponse(transformedData);
        console.log({ transformedData });
    };

    const handlePrefix = async (event) => {
        setInputValue(event.target.value);
        const response = await getInsuranceDetails(event.target.value, year, insurance);
        await getInsuranceList(event.target.value);
        setInsurance(insuranceValues[0]?.value);
        setResponse(response?.data);
        const transformedData = {}
        response?.data?.forEach(currentValue => {
            for (const [category, values] of Object.entries(currentValue)) {
                transformedData[category] = {};
                for (const [key, value] of Object.entries(values)) {
                    transformedData[category][key] = typeof value === 'number' ? (value * 100).toFixed(1) : value;
                }
            }
        });

        setResponse(transformedData);
        console.log({ transformedData });
    };
    console.log("Response--->", response);
    useEffect(() => {
        const fetchData = async () => {
            await handleInsurance();
            setYear(items[0]?.value);
        };

        fetchData();
    }, []);

    return (
        <div className="collections">
            <div className="prefix-container">
                <Link to="/"><div className="blue-left-arrow"></div></Link>
                <Link to="/prefix-lookup"><div className="blue-right-arrow"></div></Link>
            </div>
            <div className="prefix-header">
                <div className="form">
                    <table className="padding width">
                        <tbody>
                            <tr>
                                <td className="flex-container">
                                    <label className='margin-top'>Prefix look up</label>
                                    <input
                                        id="prefixLookup-field"
                                        type="text"
                                        value={inputValue}
                                        onChange={handlePrefix}
                                    />
                                    <CustomSelect value={year} onChange={handleDateChange} items={items} sx={{ marginLeft: '20px', width: '15rem', marginTop: '10px' }} height={75} />
                                </td>
                            </tr>
                            <tr>
                                <td className="flex-container">
                                    <div className="placeholder-label font-label">Insurance</div>
                                    <CustomSelect value={insurance} onChange={handleInsurance} items={insuranceValues} sx={{ marginLeft: '39px', width: '36rem', marginTop: '10px' }} height={45} />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                <div className="flex">
                    <table className="padding width">
                        <tbody>
                            <tr>
                                <td className="flex-container margin-prefix padding">
                                    <div className="font-label"></div>
                                    <div className="placeholder-label font-label">Florida</div>
                                    <div className="placeholder-label font-label">South New Jersey</div>
                                    <div className="placeholder-label font-label">Ohio</div>
                                    <div className="placeholder-label font-label">North New Jersey</div>
                                </td>
                            </tr>
                            <tr>
                                <td className="flex-container padding">
                                    <div className="placeholder-label font-label">INTAKE</div>
                                    {response?.INTAKE?.LHCM ? (<div className="placeholder-per font-label start">{response.INTAKE.LHCM + "%"}</div>) : (<div className=" placeholder-per font-label block-none start"></div>)}
                                    {response?.INTAKE?.LHNJ ? (<div className="placeholder-per font-label">{response.INTAKE.LHNJ + "%"}</div>) : (<div className=" placeholder-per font-label block-none"></div>)}
                                    {response?.INTAKE?.LHCO ? (<div className="placeholder-per font-label">{response.INTAKE.LHCO + "%"}</div>) : (<div className=" placeholder-per font-label block-none"></div>)}
                                    {response?.INTAKE?.LHNNJ ? (<div className="placeholder-per font-label">{response.INTAKE.LHNNJ + "%"}</div>) : (<div className=" placeholder-per font-label block-none"></div>)}
                                </td>
                            </tr>
                            <tr>
                                <td className="flex-container padding">
                                    <div className="placeholder-label font-label">IOP %</div>
                                    {response?.IOP?.LHCM ? (<div className="placeholder-per font-label start">{response.IOP.LHCM + "%"}</div>) : (<div className=" placeholder-per font-label block-none start"></div>)}
                                    {response?.IOP?.LHNJ ? (<div className="placeholder-per font-label">{response.IOP.LHNJ + "%"}</div>) : (<div className=" placeholder-per font-label block-none"></div>)}
                                    {response?.IOP?.LHCO ? (<div className="placeholder-per font-label">{response.IOP.LHCO + "%"}</div>) : (<div className=" placeholder-per font-label block-none"></div>)}
                                    {response?.IOP?.LHNNJ ? (<div className="placeholder-per font-label">{response.IOP.LHNNJ + "%"}</div>) : (<div className=" placeholder-per font-label block-none"></div>)}
                                </td>
                            </tr>
                            <tr>
                                <td className="flex-container padding">
                                    <div className="placeholder-label font-label">PHP %</div>
                                    {response?.PHP?.LHCM ? (<div className="placeholder-per font-label start">{response.PHP.LHCM + "%"}</div>) : (<div className=" placeholder-per font-label block-none start"></div>)}
                                    {response?.PHP?.LHNJ ? (<div className="placeholder-per font-label">{response.PHP.LHNJ + "%"}</div>) : (<div className=" placeholder-per font-label block-none"></div>)}
                                    {response?.PHP?.LHCO ? (<div className="placeholder-per font-label">{response.PHP.LHCO + "%"}</div>) : (<div className=" placeholder-per font-label block-none"></div>)}
                                    {response?.PHP?.LHNNJ ? (<div className="placeholder-per font-label">{response.PHP.LHNNJ + "%"}</div>) : (<div className=" placeholder-per font-label block-none"></div>)}
                                </td>
                            </tr>
                            <tr>
                                <td className="flex-container padding">
                                    <div className="placeholder-label font-label">OP %</div>
                                    {response?.OP?.LHCM ? (<div className="placeholder-per font-label start">{response.OP.LHCM + "%"}</div>) : (<div className=" placeholder-per font-label block-none start"></div>)}
                                    {response?.OP?.LHNJ ? (<div className="placeholder-per font-label">{response.OP.LHNJ + "%"}</div>) : (<div className=" placeholder-per font-label block-none"></div>)}
                                    {response?.OP?.LHCO ? (<div className="placeholder-per font-label">{response.OP.LHCO + "%"}</div>) : (<div className=" placeholder-per font-label block-none"></div>)}
                                    {response?.OP?.LHNNJ ? (<div className="placeholder-per font-label">{response.OP.LHNNJ + "%"}</div>) : (<div className=" placeholder-per font-label block-none"></div>)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <img src={logo} className="image-sm"></img>
        </div>
    );
};

export default Prefix;
