import { Routes, Route } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react"; // Import the useAuthenticator hook
import Home from './Pages/Home';
import Support from './Pages/Home/support';
import Instructions from './Pages/Instructions';
import Collections from './Pages/collections-review';
import Prefix from './Pages/prefix-lookup';
import Prefix2 from './Pages/prefix-lookup/prefix2';
import Login from './Pages/Login/login';
import Headers from './component/headers'

function App() {
  const { user } = useAuthenticator(); // Use the useAuthenticator hook to access the current user's authentication state
  return (
    <>
    <Headers/>
    <Routes>
      <Route path="/support" element={user ? <Support /> : <Login />} /> {/* Render Home if user is authenticated, otherwise render Login */}
      <Route path="/" element={user ? <Home /> : <Login />} /> {/* Render Home if user is authenticated, otherwise render Login */}
      <Route path="/instructions" element={user ? <Instructions /> : <Login />} /> {/* Render Instructions if user is authenticated, otherwise render Login */}
      <Route path="/collections-review" element={user ? <Collections /> : <Login />} /> {/* Render Collections if user is authenticated, otherwise render Login */}
      <Route path="/prefix" element={user ? <Prefix /> : <Login />} /> {/* Render Prefix if user is authenticated, otherwise render Login */}
      <Route path="/prefix-lookup" element={user ? <Prefix2 /> : <Login />} /> {/* Render Prefix2 if user is authenticated, otherwise render Login */}
    </Routes>
    </>
  );
}

export default App;
